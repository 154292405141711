
.v-chip {
    min-width: none;
    // width: 100%;
    // justify-content: center;
}
@media (min-width: 1024px) {
    .v-chip {
        width: 100%;
    }
}
